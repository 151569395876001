import { render, staticRenderFns } from "./LeadInformationSkeleton.vue?vue&type=template&id=064ac110&scoped=true"
var script = {}
import style0 from "./LeadInformationSkeleton.vue?vue&type=style&index=0&id=064ac110&prod&scoped=true&lang=css"
import style1 from "./LeadInformationSkeleton.vue?vue&type=style&index=1&id=064ac110&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064ac110",
  null
  
)

export default component.exports